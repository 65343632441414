import logo from './logo.svg';
import './App.css';
import Webpages from './webpages';

function App() {
  document.title = 'Tạp chí Rừng và Môi trường';

  return (
    <div>
      
      <Webpages />
    </div>

  );
}

export default App;
